import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import ListadoUsuariosAdmin from '../components/layout/Login/ListadoUsuariosAdmin'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import ListadoPlanteles from './views/Catalogos/Planteles/ListadoPlanteles'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'
import ListadoDepartamentos from './views/Catalogos/Departamentos/ListadoDepartamentos'
import ListadoGrupos from './views/Catalogos/Grupos/ListadoGrupos'

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoLineas from './views/Articulos/Lineas/ListadoLineas'
import ListadoColores from './views/Articulos/Colores/ListadoColores'
import ListadoTallas from "./views/Articulos/Tallas/ListadoTallas.jsx";
import ListadoMarcas from './views/Articulos/Marcas/ListadoMarcas'
import ListadoFamilias from './views/Articulos/Familias/ListadoFamilias'
import ListadoColecciones from './views/Articulos/Colecciones/ListadoColecciones'
import ListadoComposiciones from './views/Articulos/Composiciones/ListadoComposiciones'
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ListadoArticulosViejo from './views/Articulos/Articulos/ListadoArticulosViejo'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'
import ArticulosFichaTecnica from "./views/Articulos/Articulos/ArticulosFichaTecnica.jsx";

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import BotonesDisponibles from "./views/Inventarios/BotonesDisponibles.jsx";
import ListadoInventarios from './views/Inventarios/Inventarios/ListadoInventarios'
import ListadoDisponible from "./views/Inventarios/Inventarios/ListadoDisponible";
import ListadoKardex from "./views/Inventarios/Inventarios/ListadoKardex";
import ListadoTracking from "./views/Inventarios/Inventarios/ListadoTracking";
import ListadoDisponibleVendedor from "./views/Inventarios/Inventarios/ListadoDisponibleVendedor";
import ListadoAjustesInventario from "./views/Inventarios/Ajustes/ListadoAjustesInventario"
import AjustesInventarioCreate from "./views/Inventarios/Ajustes/AjustesInventarioCreate"

// Tiendas
import BotonesTiendas from "./views/Tiendas/BotonesTiendas"
import ListadoInventariosTiendas from './views/Tiendas/ListadoInventariosTiendas'
import ListadoVentasTiendas from './views/Tiendas/VentasTiendas/ListadoVentasTiendas'
import VentasTiendasCreate from './views/Tiendas/VentasTiendas/VentasTiendasCreate'
import AbonosTiendasCreate from './views/Tiendas/AbonosTiendas/AbonosTiendasCreate'
import ListadoAbonosTiendas from './views/Tiendas/AbonosTiendas/ListadoAbonosTiendas'
import ListadoGastosTiendas from './views/Tiendas/GastosTiendas/ListadoGastosTiendas'
import GastosTiendasCreate from './views/Tiendas/GastosTiendas/GastosTiendasCreate'
import ListadoDevolucionesTiendas from './views/Tiendas/DevolucionesTiendas/ListadoDevolucionesTiendas'
import DevolucionesTiendasCreate from './views/Tiendas/DevolucionesTiendas/DevolucionesTiendasCreate'
import CorteTiendas from "./views/Tiendas/CorteTiendas/CorteTiendas";
import CorteTiendasK from "./views/Tiendas/CorteTiendas/CorteTiendasK";
import EntregarNota from "./views/Tiendas/VentasTiendas/EntregarNota";
import EstadoCuentaTiendas from "./views/Tiendas/EstadoCuentaTiendas/EstadoCuentaTiendas";
import EstadoCuentaTiendasAnalisis from "./views/Tiendas/EstadoCuentaTiendas/EstadoCuentaTiendasAnalisis";
import CotizacionesTiendasCreate from "./views/Tiendas/CotizacionesTiendas/CotizacionesTiendasCreate";
import ListadoCotizacionesTiendas from "./views/Tiendas/CotizacionesTiendas/ListadoCotizacionesTiendas";

// Traspasos
import ListadoTraspasosSalida from './views/Inventarios/Traspasos/ListadoTraspasosSalida'
import ListadoTraspasosSalidaNoEvidencia from './views/Inventarios/Traspasos/ListadoTraspasosSalidaNoEvidencia.jsx'
import TraspasosEntradaCreate from './views/Inventarios/Traspasos/TraspasosEntradaCreate'
import TraspasosSalidaCreate from './views/Inventarios/Traspasos/TraspasosSalidaCreate'
import ListadoTraspasosEntrada from './views/Inventarios/Traspasos/ListadoTraspasosEntrada'
import TraspasosSolicitudCreate from './views/Inventarios/Traspasos/TraspasosSolicitudV2Create'
import ListadoTraspasosSolicitud from './views/Inventarios/Traspasos/ListadoTraspasosSolicitudV2'
import ListadoPendienteTraspasar from './views/Inventarios/Traspasos/ListadoPendienteTraspasar'

// Muestras
import MuestrasSalidaCreate from './views/Inventarios/Muestras/MuestrasSalidaCreate'
import ListadoMuestrasSalida from './views/Inventarios/Muestras/ListadoMuestrasSalida'
import MuestrasEntradaCreate from './views/Inventarios/Muestras/MuestrasEntradaCreate'
import ListadoMuestrasEntrada from './views/Inventarios/Muestras/ListadoMuestrasEntrada'

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import ListadoEmbarques from './views/Compras/Embarques/ListadoEmbarques'
import EmbarquesCreate from './views/Compras/Embarques/EmbarquesCreate'
import EmbarquesDirectosCreate from "./views/Compras/Embarques/EmbarquesDirectosCreate.jsx";
import ListadoPendEmbarcar from "./views/Compras/Embarques/ListadoPendEmbarcar";
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ListadoPendRecibir from "./views/Compras/Compras/ListadoPendRecibir";
import ListadoConceptosGastosEmbarques from "./views/Compras/ConceptosGastosEmbarques/ListadoConceptosGastosEmbarques";
import ListadoControlCalidad from "./views/Compras/ControlCalidad/ListadoControlCalidad";
import RegistroControlCalidad from "./views/Compras/ControlCalidad/RegistroControlCalidad";
import ControlCalidadCreate from "./views/Compras/ControlCalidad/ControlCalidadCreate";
import ComprasDirectasCreate from "./views/Compras/Compras/ComprasDirectasCreate.jsx";

// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
import ListadoPendienteSurtir from './views/Ventas/Surtidos/ListadoPendienteSurtir'
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'
import ListadoDepartamentosClientes from './views/Ventas/DepartamentosClientes/ListadoDepartamentosClientes'
import AnalisisArticulosPedidos from "./views/Ventas/Pedidos/AnalisisArticulosPedidos";
import VentaDirectaCreate from "./views/Ventas/Pedidos/VentaDirectaCreate";


// {/* Gastos */}
import BotonesGastos from "./views/ConfigAdmin/BotonesGastos"
import ListadoGastosGeneral from './views/ConfigAdmin/GastosGeneral/ListadoGastosGeneral'
import GastosGeneralCreate from './views/ConfigAdmin/GastosGeneral/GastosGeneralCreate'
import ListadoConceptosGastos from './views/ConfigAdmin/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/ConfigAdmin/ConceptosGastos/ConceptosGastosCreate'
import ListadoCategorias from "./views/ConfigAdmin/Categorias/ListadoCategorias";
import CategoriasCreate from "./views/ConfigAdmin/Categorias/CategoriasCreate";
import ListadoSubCategorias from "./views/ConfigAdmin/SubCategorias/ListadoSubCategorias";
import SubCategoriasCreate from "./views/ConfigAdmin/SubCategorias/SubCategoriasCreate";
import ListadoSolicitantes from "./views/ConfigAdmin/Solicitantes/ListadoSolicitantes";
import SolicitantesCreate from "./views/ConfigAdmin/Solicitantes/SolicitantesCreate";


//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"


//Reportes
import BotonesReportes from './views/Reportes/BotonesReportes'
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import GraficaVentasMensualVendedor from "./views/Reportes/GraficaVentasMensualVendedor";
import GraficaVentasDepartamento from "./views/Reportes/GraficaVentasDepartamento";
import GraficaVentasGrupo from "./views/Reportes/GraficaVentasGrupo";
import GraficaVentasVendedor from "./views/Reportes/GraficaVentasVendedor";
import GraficaVentasEstado from "./views/Reportes/GraficaVentasEstado";
import ReporteUltimoPrecio from "./views/Reportes/ReporteUltimoPrecio";
import ReporteUltimoPrecioArticulo from "./views/Reportes/ReporteUltimoPrecioArticulo";
import GraficaVentasMensualCliente from "./views/Reportes/GraficaVentasMensualCliente";
import ValorInventario from './views/Reportes/ValorInventario'
import ListadoSellThru from "./views/Reportes/ListadoSellThru";
import ListadoSellThruArea from "./views/Reportes/ListadoSellThruArea";
// import ListadoSellThruAreaGlobal from "./views/Reportes/ListadoSellThruAreaGlobal";
import CalificadoraClientes from "./views/Reportes/CalificadoraClientes";
import BotonesReportesVentas from "./views/Reportes/BotonesReportesVentas";
import BotonesReportesAdmin from "./views/Reportes/BotonesReportesAdmin";
import GraficaVentasMensualComparativa from "./views/Reportes/GraficaVentasMensualComparativa";
import GraficaVentasDesgloce from "./views/Reportes/GraficaVentasDesgloce";

// Dashboards
import BotonesDashboards from "./views/Reportes/BotonesDashboards";
import DashboardInventarios from "./views/Reportes/DashboardInventarios";
import DashboardCXP from "./views/Reportes/DashboardCXP";
import DashboardCobranza from "./views/Reportes/DashboardCobranza";
import DashboardVentas from "./views/Reportes/DashboardVentas";
import DashboardTiendas from "./views/Reportes/DashboardTiendas";
import DashboardCompras from "./views/Reportes/DashboardCompras";
import DashboardVentasTabla from "./views/Reportes/DashboardVentasTabla";
import DashboardTracking from "./views/Reportes/DashboardTracking";
import DashboardGastos from "./views/Reportes/DashboardGastos";
import Balance from "./views/Reportes/Balance";
import AntiguedadSaldosPorCobrar from "./views/Reportes/AntiguedadSaldosPorCobrar";
import AntiguedadSaldosVencido from "./views/Reportes/AntiguedadSaldosVencido.jsx";
import Locker from "./views/Reportes/Locker.jsx";
import VentaAcumulado from "./views/Reportes/VentaAcumulado.jsx";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Monitoring
import Monitoring from "./views/Monitoring/Monitoring";


// Config
import Configuraciones from './views/Configuracion/Configuraciones'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import ListadoMovimientosBancos from "./views/Admin/Bancos/ListadomovimientosBancos";
import AnticiposCreate from './views/Admin/Anticipos/AnticiposCreate'
import ListadoAnticipos from './views/Admin/Anticipos/ListadoAnticipos'
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ComisionesCreate from "./views/Admin/Comisiones/ComisionesCreate";
import ListadoComisiones from "./views/Admin/Comisiones/ListadoComisiones";
import EstadoCuentaColaboradores from "./views/Admin/Comisiones/EstadoCuentaColaboradores";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";
import ListadoVentasNoEvidencia from "./views/Admin/Ventas/ListadoVentasNoEvidencia.jsx";
import SaldoInicialCreate from './views/Admin/SaldoInicial/SaldoInicialCreate'
import ListadoSaldoInicial from './views/Admin/SaldoInicial/ListadoSaldoInicial'

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'

//Traspasos Dinero
import TraspasosDineroCreate from './views/Tiendas/TraspasosDinero/TraspasosDineroCreate'
import ListadoTraspasosDinero from './views/Tiendas/TraspasosDinero/ListadoTraspasosDinero'


// Whastapp
import Whatsapp from "./views/WhatsApp/Whatsapp";

// AI
import ListadoAI from "./views/AI/ListadoAI.jsx";
import DisenoAI from "./views/AI/DisenoAI.jsx";

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                <Route exact path ="/MenuUsuariosAdmin" component={ListadoUsuariosAdmin} />
                {/* Catalogos */}
                <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                {/* Articulos */}
                <Route exact path ="/MenuArticulos" component={BotonesArticulos} />
                <Route exact path ="/Lineas" component={ListadoLineas} />
                <Route exact path ="/Colores" component={ListadoColores} />
                <Route exact path ="/Tallas" component={ListadoTallas} />
                <Route exact path ="/Marcas" component={ListadoMarcas} />
                <Route exact path ="/Familias" component={ListadoFamilias} />
                <Route exact path ="/Colecciones" component={ListadoColecciones} />
                <Route exact path ="/Composiciones" component={ListadoComposiciones} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ListadoArticulosViejo" component={ListadoArticulosViejo} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                <Route exact path ="/ArticulosFichaTecnica" component={ArticulosFichaTecnica} />
                {/* Inventarios */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/MenuDisponibles" component={BotonesDisponibles} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoKardex" component={ListadoKardex} />
                <Route exact path ="/ListadoTracking" component={ListadoTracking} />
                <Route exact path ="/ListadoAjustesInventario" component={ListadoAjustesInventario} />
                <Route exact path ="/AjustesInventarioCreate" component={AjustesInventarioCreate} />
                {/* Tiendas */}
                <Route exact path ="/MenuTiendas" component={BotonesTiendas} />
                <Route exact path ="/ListadoInventariosTiendas" component={ListadoInventariosTiendas} />  
                <Route exact path ="/ListadoVentasTiendas" component={ListadoVentasTiendas} />       
                <Route exact path ="/VentasTiendasCreate" component={VentasTiendasCreate} />        
                <Route exact path ="/AbonosTiendasCreate" component={AbonosTiendasCreate} />  
                <Route exact path ="/ListadoAbonosTiendas" component={ListadoAbonosTiendas} />
                <Route exact path ="/GastosTiendas" component={ListadoGastosTiendas} />
                <Route exact path ="/GastosTiendasCreate" component={GastosTiendasCreate} />  
                <Route exact path ="/ListadoDevolucionesTiendas" component={ListadoDevolucionesTiendas} />
                <Route exact path ="/DevolucionesTiendasCreate" component={DevolucionesTiendasCreate} />  
                <Route exact path ="/CorteTiendas" component={CorteTiendas} />    
                <Route exact path ="/CorteTiendasK" component={CorteTiendasK} />    
                <Route exact path ="/EntregarNota" component={EntregarNota} />    
                <Route exact path ="/EstadoCuentaTiendas" component={EstadoCuentaTiendas} />  
                <Route exact path ="/EstadoCuentaTiendasAnalisis" component={EstadoCuentaTiendasAnalisis} />  
                <Route exact path ="/CotizacionesTiendasCreate" component={CotizacionesTiendasCreate} />    
                <Route exact path ="/CotizacionesTiendas" component={ListadoCotizacionesTiendas} />    
                {/* Traspasos */}
                <Route exact path ="/ListadoTraspasosSalida" component={ListadoTraspasosSalida} />
                <Route exact path ="/ListadoTraspasosSalidaNoEvidencia" component={ListadoTraspasosSalidaNoEvidencia} />
                <Route exact path ="/TraspasosEntradaCreate" component={TraspasosEntradaCreate} />
                <Route exact path ="/TraspasosSalidaCreate" component={TraspasosSalidaCreate} />
                <Route exact path ="/ListadoTraspasosEntrada" component={ListadoTraspasosEntrada} />
                <Route exact path ="/TraspasosSolicitudCreate" component={TraspasosSolicitudCreate} />
                <Route exact path ="/ListadoTraspasosSolicitud" component={ListadoTraspasosSolicitud} />
                <Route exact path ="/ListadoPendienteTraspasar" component={ListadoPendienteTraspasar} />
                {/* Muestras */}
                <Route exact path ="/MuestrasSalidaCreate" component={MuestrasSalidaCreate} />
                <Route exact path ="/ListadoMuestrasSalida" component={ListadoMuestrasSalida} />
                <Route exact path ="/MuestrasEntradaCreate" component={MuestrasEntradaCreate} />
                <Route exact path ="/ListadoMuestrasEntrada" component={ListadoMuestrasEntrada} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/ListadoEmbarques" component={ListadoEmbarques} />
                <Route exact path ="/EmbarquesCreate" component={EmbarquesCreate} />
                <Route exact path ="/EmbarquesDirectosCreate" component={EmbarquesDirectosCreate} />
                <Route exact path ="/ListadoPendEmbarcar" component={ListadoPendEmbarcar} />   
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ComprasDirectasCreate" component={ComprasDirectasCreate} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />  
                <Route exact path ="/ConceptosGastosEmbarques" component={ListadoConceptosGastosEmbarques} />  
                <Route exact path ="/ListadoControlCalidad" component={ListadoControlCalidad} />
                <Route exact path ="/RegistroControlCalidad" component={RegistroControlCalidad} />
                <Route exact path ="/ControlCalidadCreate" component={ControlCalidadCreate} />
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                <Route exact path ="/ListadoPendienteSurtir" component={ListadoPendienteSurtir} />
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/DepartamentosClientes" component={ListadoDepartamentosClientes} />
                <Route exact path ="/AnalisisArticulosPedidos" component={AnalisisArticulosPedidos} />
                <Route exact path ="/VentaDirectaCreate" component={VentaDirectaCreate} />
                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                <Route exact path ="/Gastos" component={ListadoGastosGeneral} />
                <Route exact path ="/GastosCreate" component={GastosGeneralCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                <Route exact path ="/Categorias" component={ListadoCategorias} />
                <Route exact path ="/CategoriasCreate" component={CategoriasCreate} />
                <Route exact path ="/SubCategorias" component={ListadoSubCategorias} />
                <Route exact path ="/SubCategoriasCreate" component={SubCategoriasCreate} />
                <Route exact path ="/Solicitantes" component={ListadoSolicitantes} />
                <Route exact path ="/SolicitantesCreate" component={SolicitantesCreate} />
                {/* Clientes */}
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/Grupos" component={ListadoGrupos} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/MenuReportesAdmin" component={BotonesReportesAdmin} />
                <Route exact path ="/MenuReportesVentas" component={BotonesReportesVentas} />
                <Route exact path ="/GraficaVentasMensual" component={GraficaVentasMensual} />
                <Route exact path ="/GraficaVentasMensualComparativa" component={GraficaVentasMensualComparativa} />
                <Route exact path ="/GraficaVentasMensualCliente" component={GraficaVentasMensualCliente} />
                <Route exact path ="/GraficaVentasMensualVendedor" component={GraficaVentasMensualVendedor} />
                <Route exact path ="/GraficaVentasDesgloce" component={GraficaVentasDesgloce} />
                <Route exact path ="/GraficaVentasDepartamento" component={GraficaVentasDepartamento} />
                <Route exact path ="/GraficaVentasGrupo" component={GraficaVentasGrupo} />
                <Route exact path ="/GraficaVentasVendedor" component={GraficaVentasVendedor} />
                <Route exact path ="/GraficaVentasEstado" component={GraficaVentasEstado} />
                <Route exact path ="/ReporteUltimoPrecio" component={ReporteUltimoPrecio} />
                <Route exact path ="/ReporteUltimoPrecioArticulo" component={ReporteUltimoPrecioArticulo} />
                <Route exact path ="/ValorInventario" component={ValorInventario} />
                <Route exact path ="/SellThru" component={ListadoSellThru} />
                <Route exact path ="/SellThruArea" component={ListadoSellThruArea} />
                {/* <Route exact path ="/SellThruAreaGlobal" component={ListadoSellThruAreaGlobal} /> */}
                <Route exact path ="/CalificadoraClientes" component={CalificadoraClientes} />

                {/* Dashboards */}
                <Route exact path ="/Dashboards" component={BotonesDashboards} />
                <Route exact path ="/DashboardCXP" component={DashboardCXP} />
                <Route exact path ="/DashboardCobranza" component={DashboardCobranza} />
                <Route exact path ="/DashboardInventarios" component={DashboardInventarios} />
                <Route exact path ="/DashboardVentas" component={DashboardVentas} />
                <Route exact path ="/DashboardTiendas" component={DashboardTiendas} />
                <Route exact path ="/DashboardCompras" component={DashboardCompras} />
                <Route exact path ="/DashboardVentasTabla" component={DashboardVentasTabla} />
                <Route exact path ="/DashboardTracking" component={DashboardTracking} />
                <Route exact path ="/DashboardGastos" component={DashboardGastos} />
                <Route exact path ="/Balance" component={Balance} />
                <Route exact path ="/AntiguedadSaldosPorCobrar" component={AntiguedadSaldosPorCobrar} />
                <Route exact path ="/AntiguedadSaldosVencido" component={AntiguedadSaldosVencido} />
                <Route exact path ="/Locker" component={Locker} />
                <Route exact path ="/VentaAcumulado" component={VentaAcumulado} />


                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Monitoring  */}
                <Route exact path ="/Monitoring" component={Monitoring} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/MovimientosBancos" component={ListadoMovimientosBancos} />
                <Route exact path ="/AnticiposCreate" component={AnticiposCreate} />
                <Route exact path ="/ListadoAnticipos" component={ListadoAnticipos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ComisionesCreate" component={ComisionesCreate} />
                <Route exact path ="/ListadoComisiones" component={ListadoComisiones} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/ListadoVentasNoEvidencia" component={ListadoVentasNoEvidencia} />
                <Route exact path ="/EstadoCuentaVendedores" component={EstadoCuentaColaboradores} />
                <Route exact path ="/SaldoInicialCreate" component={SaldoInicialCreate} />
                <Route exact path ="/ListadoSaldoInicial" component={ListadoSaldoInicial} />
                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                {/* Traspasos Dinero */}
                <Route exact path ="/TraspasosDineroCreate" component={TraspasosDineroCreate} />
                <Route exact path ="/ListadoTraspasosDinero" component={ListadoTraspasosDinero} />
                {/* Whatsapp */}
                <Route exact path ="/Whatsapp" component={Whatsapp} />
                {/* AI */}
                <Route exact path ="/AI" component={ListadoAI} />
                <Route exact path ="/DisenoAI" component={DisenoAI} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
