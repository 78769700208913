import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";

function DashboardTracking() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [totalTotalCantidad, setTotalTotalCantidad] = useState(0);
  const [totalTotalImporte, setTotalTotalImporte] = useState(0);
  const [totalTotalCompraCantidad, setTotalTotalCompraCantidad] = useState(0);
  const [totalTotalCompraImporte, setTotalTotalCompraImporte] = useState(0);
  

  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}ComprasVentasTabla/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setComments(array)

      let unicosLinea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLinea);

      let can = allDisponible.map((c) => parseFloat(c.Cantidad));
      let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalCantidad(TCan);

      let imp = allDisponible.map((c) => parseFloat(c.Importe));
      let Timp = imp.reduce((t, cajas, index) => t + cajas, 0);
      setTotalTotalImporte(Timp);

      
      let canComp = allDisponible.map((c) => parseFloat(c.CantidadCompra));
      let TCanComp = canComp.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalCompraCantidad(TCanComp);

      let impComp = allDisponible.map((c) => parseFloat(c.ImporteCompra));
      let TimpComp = impComp.reduce((t, cajas, index) => t + cajas, 0);
      setTotalTotalCompraImporte(TimpComp);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

  
},[])

function BuscaInfo(){
  setComments([])
  setSelectedLinea("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}ComprasVentasTabla/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setComments(array)

      let unicosLinea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLinea);

      let can = allDisponible.map((c) => parseFloat(c.Cantidad));
      let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalCantidad(TCan);

      let imp = allDisponible.map((c) => parseFloat(c.Importe));
      let Timp = imp.reduce((t, cajas, index) => t + cajas, 0);
      setTotalTotalImporte(Timp);

      
      let canComp = allDisponible.map((c) => parseFloat(c.CantidadCompra));
      let TCanComp = canComp.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalCompraCantidad(TCanComp);

      let impComp = allDisponible.map((c) => parseFloat(c.ImporteCompra));
      let TimpComp = impComp.reduce((t, cajas, index) => t + cajas, 0);
      setTotalTotalCompraImporte(TimpComp);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}



  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) 
      ) {
        return [
          a.Codigo,
          new Intl.NumberFormat("en-US").format(a.Cantidad),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Reporte Ventas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        ["Nombre", "Cantidad","Importe", "Almacen"],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`DashboardVentasTabla.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) 
      ) {
        return {
          Nombre: a.Codigo,
          Cantidad: a.Cantidad,
          Importe: a.Importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAjustesInventario";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAjustesInventario",
        sheetFilter: [
          "Nombre",
          "Cantidad",
          "Importe",
          "Almacen",
        ],
        sheetHeader: [
          "Nombre",
          "Cantidad",
          "Importe",
          "Almacen",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) 
      ) {
      return [
        a.Codigo,
        new Intl.NumberFormat("en-US").format(a.Cantidad),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Importe),
      ];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape", "mm", "a4", true);
  var img = new Image();
  
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 120, 15, 45, 15);
  doc.text(`Reporte Ventas`, 20, 30);
  doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
  doc.autoTable({
    head: [
      ["Nombre", "Cantidad","Importe", "Almacen"],
    ],
    body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
    startY: 45,
  });

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte Ventas",
          email: mailTo,
          fileName: "ReporteVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Reporte de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }




  const headers = [
    { name: "Nombre", field: "Codigo", sortable: true },
    { name: "Cantidad Comprada", field: "CantidadCompra", sortable: true },
    { name: "Cantidad Vendida", field: "Cantidad", sortable: true },
    { name: "Importe Compra", field: "ImporteCompra", sortable: true },
    { name: "Importe Venta", field: "Importe", sortable: true },
    { name: "Cantidad en Inventario", field: "cantInventario", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.Codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.Cantidad.toString().includes(search) ||
          comment.Importe.toString().includes(search) ||
          comment.CantidadCompra.toString().includes(search) ||
          comment.ImporteCompra.toString().includes(search)
      );
    }

    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }
 

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "Importe" &&
      sorting.field != "Cantidad" &&
      sorting.field != "ImporteCompra" &&
      sorting.field != "CantidadCompra" &&
      sorting.field != "cantInventario" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "Importe" || sorting.field == "Cantidad" || sorting.field == "ImporteCompra" || sorting.field == "CantidadCompra" || sorting.field == "cantInventario")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "Importe" || sorting.field == "Cantidad" || sorting.field == "ImporteCompra" || sorting.field == "CantidadCompra" || sorting.field == "cantInventario")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedLinea, 
  ]);

let totalCantidad = 0
let totalImporte = 0
let totalCompraCantidad = 0
let totalCompraImporte = 0
 
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
            
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ventas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              onClick={enviaMail}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Dashboard Tracking</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Lineas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedLinea}
                required
                onChange={(e) => {
                  setSelectedLinea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {lineas
                  .sort((a, b) => (a.Linea > b.Linea ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idLinea}>{a.Linea}</option>;
                  })}
              </Input>
            </Col>

        </Row> 
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                totalCantidad = totalCantidad + parseFloat(a.Cantidad);
                totalImporte = totalImporte + parseFloat(a.Importe);
                totalCompraCantidad = totalCompraCantidad + parseFloat(a.CantidadCompra);
                totalCompraImporte = totalCompraImporte + parseFloat(a.ImporteCompra);
                  return (
                    <tr>
                      <td>{a.Codigo}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.CantidadCompra)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.Cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImporteCompra)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Importe)}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.cantInventario)}</td>
                    </tr>
                  );
              })}
              <tr>
                <td className="negrita">Total</td>
                <td className="negrita">{new Intl.NumberFormat("en-US").format(totalCompraCantidad)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US").format(totalCantidad)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCompraImporte)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImporte)}</td>
                <td></td>      
              </tr>
              <tr>
                <td className="negrita">Total Total</td>
                <td className="negrita">{new Intl.NumberFormat("en-US").format(totalTotalCompraCantidad)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US").format(totalTotalCantidad)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalCompraImporte)}</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalImporte)}</td>
                <td></td>      
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      
      {loader}
    </>
  );
}

export default DashboardTracking;
