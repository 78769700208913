import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { encode } from "base64-arraybuffer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  let endDate   = moment(date).format("YYYY-MM-DD"); 
  let startDate   = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorNombre, setSelectedColaboradorNombre] = useState("");
  const [codigoCliente, setCodigoCliente] = useState("");
  const [nombreCliente, setNombreCliente] = useState("");
  const [emailCliente, setEmailCliente] = useState("");
  const [telefonoCliente, setTelefonoCliente] = useState("");
  const [value, setValue] = useState("");
  const [departamentoCliente, setDepartamentoCliente] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [paqueteria, setPaqueteria] = useState("NA");
  // const [areas, setAreas] = useState([]);
  // const [selectedArea, setSelectedArea] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [fechaInicio, setFechaInicio] = useState(startDate);
  const [fechaCancelacion, setFechaCancelacion] = useState(endDate);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      foto:"",
      fotoArticulo: "",
      urlFoto: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}General`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    // axios
    //   .get(URL_AREAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allAreas = res.data;
    //     setAreas(allAreas);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

        axios
          .get(`${URL_INVENTARIOS}Disponible`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allDisponible = response.data;
            setInventarios(allDisponible);
          })
          .catch((err) => {
            console.log(err);
          })
  }, []);

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length;

    try {
      await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            clientes: value._id,
            departamentosClientes: departamentoCliente,
            colaboradores: selectedColaborador,
            total_general: parseFloat(total_general).toFixed(2),
            observaciones,
            total_cantidad,
            paqueteria,
            comisionTDC,
            comisionFlete,
            subTotal: parseFloat(subTotal).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos: selectedImpuesto,
            surtido: "No",
            autorizadoApartado: "Si",
            autorizadoSurtido: "Si",
            editado: "No",
            fechaModificado:fecha,
            creado: user.id,
            fecha_estimada: fecha,
            fecha_entrega: fecha,
            entregaInsumos:"No",
            fechaInicio,
            fechaCancelacion,
            piezasEntregadas: 0,
            piezasPendientes: total_cantidad,
            descuento
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: data.data._id,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    cantidadProcesos: 0,
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio).toFixed(2),
                    total: parseFloat(a.total).toFixed(2),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    enviaMail(data.data.idPedido)
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Pedido",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 3000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                enviaMail(data.data.idPedido)
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Pedido",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        foto: "",
        fotoArticulo: "",
        urlFoto: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = newInputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);

  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = newInputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);

  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = newInputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);

  };

  const handleChangeInputCantidad = async (id, event) => {
    const newInputFields = await inputFields.map((i) => {
        if (id === i.id) {
            i.cantidad = event.target.value;
            i.cajas = (parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)).toFixed(2);
            i.total = parseFloat(event.target.value) * parseFloat(i.precio);  // Corrige el cálculo de total
        }
        return i;
    });

    setInputFields(newInputFields);
    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = newInputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);
};


  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo._id).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
          i.articulosNombre = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == selectedArticulo._id) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          let articulosNombre = selectedArticulo.codigo + " - " + selectedArticulo.nombre;
          let fotoArticulo = a.foto
          let urlFoto = a.urlFoto
          handleChangeInputArticulo(id, idArticulo, piezasPorCaja, precio, articulosNombre, fotoArticulo, urlFoto);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, idArticulo, piezasPorCaja, precio, articulosNombre, fotoArticulo, urlFoto) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.fotoArticulo = fotoArticulo;
        i.urlFoto = urlFoto
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();

    // Calcular Subtotal (TG)
    let importes = newInputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total) => t + total, 0);
    setSubTotal(TG);

    // Calcular descuento
    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    // Calcular IVA
    let iva = 0;
    if (selectedImpuesto != 0) {
        iva = (selectedImpuesto * (TG - temp1)) / 100;
    }
    setIva(iva);

    // Calcular total general
    let totTemp = (TG - temp1) + iva + parseFloat(comisionTDC) + parseFloat(comisionFlete);
    setTotalGeneral(totTemp);

  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
   
  }

  function calculaDescuento(e) {
    setDescuento(e);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let temp1 = (TG * e) / 100;

    setDescuentoNumero(temp1);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - temp1)) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (selectedImpuesto * (TG - temp1)) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const options2 = articulos.map((option) => {
    const junta = option.codigo + " " + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
    setValue(selectedCliente);

    axios
      .get(`${URL_CLIENTES}/${selectedCliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;

        setCodigoCliente(allCliente.codigo);
        setNombreCliente(allCliente.nombre_comercial);
        setEmailCliente(allCliente.email);
        setTelefonoCliente(allCliente.telefono);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorNombre(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id)
      })
      .catch((err) => {
        console.log(err);
      });

  }



  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    if (e != 0) {
      setIva((e * (TG - temp1)) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (e * (TG - descuentoNumero)) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - temp1)) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (selectedImpuesto * (TG - descuentoNumero)) / 100 +
      TG +
      parseFloat(e) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);

  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - temp1)) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (selectedImpuesto * (TG - descuentoNumero)) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(e) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }

  function autorizaCambio() {
    if (password == PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function enviaMail(numero){

      const data = inputFields.map((a) => {
        let foto = a.fotoArticulo === "No"
            ? process.env.PUBLIC_URL + "sinImagen.png"
            : a.urlFoto;
        return {
          // foto: a.foto,
          fotoArticulo: a.fotoArticulo,
          articulos: a.articulos,
          articulosNombre: a.articulosNombre,
          cajas: new Intl.NumberFormat("en-US").format(a.cajas),
          cantidad: new Intl.NumberFormat("en-US").format(a.cantidad),
          precio: a.precio,
          total: a.total,
          foto: foto
        };
      });
      const dataFinal = Object.values(data);
      // const dataFinalLimpia = dataFinal.filter(function (el) {
      //   return el != null;
      // });



      // dataFinal.map(async (z) => {
      //   if(z.fotoArticulo == "Si"){
      //   const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.articulos}`;
      //  await axios
      //     .get(URL_GET_MEDIA, {
      //       headers: {
      //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //       },
      //     })
      //     .then((data) => {
      //       if(data.data.secure_url != null){
      //       z.foto = data.data.secure_url
      //     }else z.foto = process.env.PUBLIC_URL + "sinImagen.png"
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      //   return z
      // }else{
      //   z.foto = process.env.PUBLIC_URL + "sinImagen.png"
      // }
      // })

      setTimeout(() => {


      const dataImg = dataFinal.map((a) => {

  

        return [
          a.foto,
          a.articulosNombre,
          new Intl.NumberFormat("en-US").format(a.cantidad) + " Pzas / " + new Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(a.cajas) + " Cajas",
          0,
          new Intl.NumberFormat("en-US").format(a.cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            
          }).format(a.precio),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            
          }).format(a.total),
        ];
      });


      const columnStyles = [
        { fontSize: .000001, textColor: [255, 255, 255], cellWidth: 20, },
        { fontSize: 9, textColor: ["#000000"] },
        { fontSize: 9, textColor: ["#000000"] },
        { fontSize: 9, textColor: ["#000000"], halign: "center" },
        { fontSize: 9, textColor: ["#000000"], halign: "center" },
        { fontSize: 9, textColor: ["#000000"], halign: "right" },
        { fontSize: 9, textColor: ["#000000"], halign: "right" },
      ];

      const doc = new jsPDF({compressPdf: true});
      var img = new Image();
      
      var img2 = new Image();
       const backgroundColor = ["#d1d2d4"];
      const backgroundColorBoxLightGray = ["#e6e7e9"];
      const backgroundColorBoxBlack = ["#231f20"];
      const backgroundColorBoxPurple = ["#4d2363"];
      const margins = { left: 5, right: 5 };

      img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
      doc.addImage(img, "jpg", 9, 12, 53, 12);

      img.src = process.env.PUBLIC_URL + "marcas.jpg";
      doc.addImage(img, "jpg", 9, 28, 192, 10);
      doc.setFontSize(10);

      doc.setFillColor(...backgroundColor);
      doc.rect(5, 40, 200, 6, "F");
      doc.setTextColor("#000000");
      doc.text(`Tel: 55 2454 9626`, 8, 44);
      doc.text(`Pedido: ${numero}`, 150, 44);

      doc.setTextColor("#000000");
      doc.text(`Cliente: ${codigoCliente} - ${nombreCliente}`, 8, 52);

      doc.setTextColor("#4d2363");
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text(`crembo.mx`, 175, 44);
      doc.setTextColor("#000000");

      doc.setFontSize(14);
      doc.setFillColor(...backgroundColorBoxBlack);
      doc.rect(100, 4, 105, 22, "F");
      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(74, 4, 25, 22, "F");
      doc.setTextColor("#ffffff");
      doc.text(`PEDIDO`, 125, 12);
      doc.setFont("helvetica", "normal");
      doc.text(`México, D. F. a ${fecha}`, 125, 22);


      doc.autoTable({
        head: [
          [
            "",
            "PRODUCTO",
            "CANTIDAD",
            "CANT. SURT.",
            // "CANT. SURTIDA",
            // "CANT. POR SURTIR",
            "CANT. X SURT.",
            // "PRECIO UNIT.",
            "PRECIO",
            "IMPORTE",
          ],
        ],
        body: dataImg,
        bodyStyles:{
          rowHeight : 22,
          valign: "middle"
        },
        styles: {
          fontSize: 10,
          overflow: "linebreak",
          columnWidth: "wrap",
          // font: 'arial',
          overflowColumns: "linebreak",
        },
        headStyles: {
          fillColor: ["#4d2363"],
          lineWidth: 0,
          halign: "center",
          valign: "middle",
          fontSize: 8,
        },
        footStyles: {
          fillColor: ["#4d2363"],
        },
        columnStyles: columnStyles,
        theme: "grid",
        margin: { left: 5, right: 5 },
        startY: 55,


      didDrawCell: function(data) {
        if (data.column.index === 0 && data.cell.section === 'body') {
           var td = data.cell.raw;
           var img = td
           var dim = data.cell.height - data.cell.padding('vertical');
           var textPos = data.cell;
           doc.addImage(img, textPos.x,  textPos.y, 20, 20);
        }
      },
      
        showFoot: "lastPage",
      });

      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(5, doc.autoTable.previous.finalY + 1, 36, 6, "F");
      doc.setTextColor("#ffffff");
      doc.setFontSize(8);
      doc.text(`TOTAL DE ARTICULOS`, 7, doc.autoTable.previous.finalY + 5);
      doc.setTextColor("#000000");
      doc.text(
        `________________________________________________________________________________________________________________________________`,
        5,
        doc.autoTable.previous.finalY + 22
      );

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.setFont("helvetica", "normal");
      doc.rect(40, doc.autoTable.previous.finalY + 1, 40, 6, "F");
      doc.text(
        new Intl.NumberFormat("en-US").format(total_cantidad) + " Piezas",
        42,
        doc.autoTable.previous.finalY + 5
      );

      doc.setFontSize(7.5);
      doc.text(
        `1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,
        5,
        doc.autoTable.previous.finalY + 11
      );
      doc.text(
        `2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,
        5,
        doc.autoTable.previous.finalY + 14
      );
      doc.text(
        `3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,
        5,
        doc.autoTable.previous.finalY + 17
      );


      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(144, doc.autoTable.previous.finalY + 1, 37, 6, "F");
      doc.setTextColor("#ffffff");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(`SUBTOTAL $`, 153, doc.autoTable.previous.finalY + 5);

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.setTextColor("#000000");
      doc.setFont("helvetica", "normal");
      doc.rect(181, doc.autoTable.previous.finalY + 1, 24, 6, "F");
      doc.text(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(subTotal.toFixed(2)),
        183,
        doc.autoTable.previous.finalY + 5
      );

      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(144, doc.autoTable.previous.finalY + 8, 37, 6, "F");
      doc.setTextColor("#ffffff");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(`IMPUESTO $`, 154, doc.autoTable.previous.finalY + 12);

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.setTextColor("#000000");
      doc.setFont("helvetica", "normal");
      doc.rect(181, doc.autoTable.previous.finalY + 8, 24, 6, "F");
      doc.text(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(iva.toFixed(2)),
        183,
        doc.autoTable.previous.finalY + 12
      );

      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(144, doc.autoTable.previous.finalY + 15, 37, 6, "F");
      doc.setTextColor("#ffffff");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(`DESCUENTO ${descuento}%`, 153, doc.autoTable.previous.finalY + 19);

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.setTextColor("#000000");
      doc.setFont("helvetica", "normal");
      doc.rect(181, doc.autoTable.previous.finalY + 15, 24, 6, "F");
      doc.text(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(((subTotal * descuento)/ 100).toFixed(2)),
        183,
        doc.autoTable.previous.finalY + 19
      );

      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(144, doc.autoTable.previous.finalY + 22, 37, 6, "F");
      doc.setTextColor("#ffffff");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(`TOTAL $`, 153, doc.autoTable.previous.finalY + 26);

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.setTextColor("#000000");
      doc.setFont("helvetica", "normal");
      doc.rect(181, doc.autoTable.previous.finalY + 22, 24, 6, "F");
      doc.text(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(total_general.toFixed(2)),
        183,
        doc.autoTable.previous.finalY + 26
      );


      var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 100);
      doc.text(5, doc.autoTable.previous.finalY + 33, lines);

      doc.setFillColor(...backgroundColorBoxLightGray);
      doc.rect(5, doc.autoTable.previous.finalY + 40, 200, 40, "F");
      doc.setFont("helvetica", "normal");
      doc.text(
        `Confirmado y autorizado por:`,
        40,
        doc.autoTable.previous.finalY + 50
      );
      doc.text(
        `Para: ${nombreCliente}`,
        125,
        doc.autoTable.previous.finalY + 50
      );
      doc.setFont("helvetica", "bold");
      doc.text(
        `______________________________`,
        35,
        doc.autoTable.previous.finalY + 65
      );
      doc.text(
        `______________________________`,
        120,
        doc.autoTable.previous.finalY + 65
      );
      doc.text(
        `FIRMA(S) AUTORIZADA(S)`,
        42,
        doc.autoTable.previous.finalY + 70
      );
      doc.text(
        `FIRMA(S) AUTORIZADA(S)`,
        125,
        doc.autoTable.previous.finalY + 70
      );

      var att = doc.output("arraybuffer");
      var base64File = encode(att);

      axios
      .post(
        `${URL_WHATSAPP}SendPlantillaPedidosExpo`,
        {
          number: telefonoCliente,
          cliente: nombreCliente,
          base64File,
          fileName: `Pedido-${numero}.pdf`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
  
      // Envia el Mail
      axios
        .post(
          `${URL_SEND_MAIL_ATT}Multi`,
          {
            subject: `Pedido-${numero} - ${nombreCliente}`,
            email: [process.env.REACT_APP_MAIL_ADMIN, process.env.REACT_APP_MAIL_ADMIN2, emailCliente],
            fileName: `Pedido-${numero}.pdf`,
            att: base64File,
            text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
           <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
           <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
           <tbody>
           <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola ${nombreCliente},</tr>
           <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Pedido ${numero}.</tr>
           </tbody>
           <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
           
           </table>`,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          Swal.fire("Good job!", "Enviado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
                },1000)
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Autocomplete
                size="small"
                value={value}
                onChange={(event, selectedCliente) => {
                  jalaCliente(selectedCliente);
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.codigo} {option.nombre_comercial}
                  </React.Fragment>
                )}
              />
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  const selectedIndex = e.target.selectedIndex;
                  const selectedOption = e.target.options[selectedIndex];
                  const selectedOptionText = selectedOption.text;
                  setSelectedColaboradorNombre(selectedOptionText);
                  setSelectedColaborador(e.target.value);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

          

            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                type="text"
                placeholder="Paqueteria"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                  setInputFields([
                    {
                      id: uuidv4(),
                      articulos: "",
                      articulosNombre: "",
                      piezasPorCaja: 0,
                      cajas: 0,
                      cantidad: 0,
                      precio: 0,
                      total: 0,
                    },
                  ])
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                        })}
              </Input>
            </Col> */}
            <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Comision Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            {/* <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col> */}
              <Col md={1}>
              <Label>Impuestos %</Label>
                <Input
                    type="select"
                    value={selectedImpuesto}
                    required
                    onChange={(e) => {
                      calculaImpuesto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="16">Si</option>
                    <option value="0">No</option>
                </Input>
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>

            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                type="date"
                value={fechaInicio}
                onChange={(e) => {
                  setFechaInicio(e.target.value);
                }}
              />
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Fecha Cancelacion</Label>
              <Input
                type="date"
                value={fechaCancelacion}
                onChange={(e) => {
                  setFechaCancelacion(e.target.value);
                }}
              />
              </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label></Label>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => toggleCambiaPrecio()}
              >
                Cambiar Precio <i class="fas fa-dollar-sign"></i>
              </Button>
            </Col>
          </Row>
          <br />

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Buscador</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Disponible</Label>
              </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cajas</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Piezas Por Caja</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Precio</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Total</Label>
            </Col>
          </Row>

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
              <Col md={2}>
              <Autocomplete
                    size="small"
                    value={inputField.articulosNombre}
                    onChange={(event, selectedArticulo) => {
                      BuscaArticulo(inputField.id, selectedArticulo);
                    }}
                    options={options2.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  {/* <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulos
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre}
                          </option>
                        );
                      })}
                  </Input> */}
                  <Input
                    name="articulo"
                    type="string"
                    placeholder="Articulo"
                    value={inputField.articulosNombre}
                    disabled
                  />
                </Col>
                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      type="select"
                      disabled
                    >
                      {inventarios
                        .map((a) => {
                          if(inputField.articulos == a.idArticulo){
                          return (
                            <option value={a._id}>
                              {a.disponible}
                            </option>
                          )}
                        })}
                    </Input>
                  </Col>
               
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cajas"
                    type="string"
                    value={inputField.cajas}
                    onChange={(event) =>
                      handleChangeInputCajas(inputField.id, event)
                    }
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="piezasPorCaja"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.piezasPorCaja}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputCantidad(inputField.id, event)
                    }
                  />
                </Col>
                {user.cambiaPrecios ? (
                  <Col md={1}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      name="precio"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={inputField.precio}
                      className={`form-control-md2`}
                      onValueChange={(value, name) =>
                        handleChangeInputPrecio(value, name, inputField.id)
                      }
                    />
                  </Col>
                ) : cambiarPrecio ? (
                  <Col md={1}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      name="precio"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={inputField.precio}
                      className={`form-control-md2`}
                      onValueChange={(value, name) =>
                        handleChangeInputPrecio(value, name, inputField.id)
                      }
                    />
                  </Col>
                ) : (
                  <Col md={1}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      name="precio"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={inputField.precio}
                      className={`form-control-md2`}
                      disabled
                    />
                  </Col>
                )}

                <Col md={1}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={inputField.total}
                    disabled
                    className={`form-control-md2-disabled`}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    // tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoPedidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Cambio de Precio</h4>
            </ModalHeader>
            <ModalBody>
              <Input
                // bsSize="sm"
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(event) => setPassword(event.target.value)}
              />
              <br />
              <Row>
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => autorizaCambio()}
                  >
                    Autorizar
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
